import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Head from '../components/head'

const About = () => {
    return (
        <div>
            <Layout>
                <Head title="About" />
                <h1>About</h1>
                <p>About page text... and more <Link to="/contact/">here</Link>.</p>
            </Layout>
        </div>
    )
}

export default About